const colors = {
  alabaster: '#EFEFEF',
  alto: '#D8D8D8',
  gray: '#8D8D8D',
  red: '#E2635D',
  redlight: '#F3C1BE',
  slate: '#202020',
  white: '#FFF',
  softblue: '#2d9be0',
  black: '#000000',

  // New color scheme as of July - see DX-601
  sagedark: '#455255',
  sagemedium: '#859395',
  sagelight: '#F0F0F0',
  sagefeather: '#F7F7F7',
  coaldark: '#313131',
  coalmedium: '#4E4A4A',
  coallight: '#989393',

  // Membership colors
  boxBackgroundGrey: '#f6f3f3',
  nonActiveGrey: '#c6c6c6',
  darkGrey: '#575756',
  level1Colour: '#033c6d',
  level2Colour: '#ca5b3f',
  level3Colour: '#004428',

  emergencyBannerBackground: '#a52433',
  relatedArticlesBackground: '#191845',

  // New colors for new offers page -- see user Story 87067
  pastelTeal: '#889494',
  teal: '#556967',

  // New colors for the new Product/Gift Guide Template -- See Story 90099
  pastelGold: '#BBA88E',
  gold: '#AD9667',
  kobe: '#8D2614',
  coolBlack: '#082265',

  // Shopping Package collor addiition
  whiteChocolate: '#EDE0D9',
  darkGold: '#8A6C45',
  midGray: '#BBB7AD',
  beaver: '#A1896A',
  darkTeal: '#434341',
  divisor: '#bbb7ad',
  lightShadow: '#d3dae2',

  // colors for Partners Service and Offers
  carouselDot: 'rgba(0,0,0,0.2)',
  carouselActiveDot: 'rgba(0,0,0,0.5)',
}

export default colors
// Village brand palettes - Keyed against the village codes we should be
// using this w styled system (https://styled-system.com/api#themeget)
// eg: themeGet(`palettes[${villageCode}].primary`) - This could be set via context
// TODO: Add unique values for each village.

export const palettes = {
  tbvsc: {
    // Collection
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  bv: {
    // Bicester
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  fv: {
    // Fidenza
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  iv: {
    // Ingolstadt
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  kv: {
    // Kildare
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  lrv: {
    // La Roca
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  lzv: {
    // Las Rozas
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  lvv: {
    // La Vallee
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  mmv: {
    // Maasmechlen
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
  wv: {
    // Wertheim
    ...colors,
    primary: colors.sagedark,
    secondary: colors.sagemedium,
    tertiary: colors.sagelight,
    warning: colors.red,
  },
}
